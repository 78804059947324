body{
    font-size: 14px;
}
#webpack-dev-server-client-overlay{
    display: none;
}
.container{
    max-width: 100% !important;
    padding: 0;
    margin: 0;
}
.list-group-item{
    border: none;
    padding: 0 3px;
}
.btn-success{
    background: #22a507 !important;
}
